<template>
  <div>
    <breadcrumb name="Galleries" title="List of Galleries" subtitle="Edit" @onTitleClicked="$emit('cancel')"/>
    <div class="body">
      <div>
        <div class="english">
          <vs-input label="Gallery Name" v-model="name"
                    size="medium"
                    class="input" :danger="flags.name" danger-text="This field is mandatory"/>
          <small class="label">Images*</small>

        </div>
        <div>
          <img-upload :images="images" title="Upload Images" @image_uploaded="saveImage" :key="imagesCounter"/>
        </div>
        <div class="flex">
          <vs-button class="ml-5 cancel" type="filled" color="primary" :disabled="invalidForm" @click="confirmSaveData">
            Save
          </vs-button>
          <vs-button class="ml-5 cancel" type="border" color="primary" @click="$emit('cancel')">
            Cancel
          </vs-button>
        </div>
        <div class="clr"/>
      </div>
    </div>
  </div>
</template>

<script>
  import ImgUpload from '../../../components/general/img-upload';
  import {ChevronsRightIcon, HomeIcon} from 'vue-feather-icons';
  import Breadcrumb from "../../../components/general/breadcrumb";

  export default {
    name: 'view-gallery',
    components: {Breadcrumb, ImgUpload, HomeIcon, ChevronsRightIcon},
    props: {
      item: {
        required: true,
        default: () => {

        }
      }
    },
    data() {
      return {
        name: this.item.name,
        images: this.item.image,
        imagesCounter: 0,
        flags: {
          name: false,
          images: false
        }
      };
    },
    methods: {
      confirmSaveData() {
        this.$vs.dialog({
          type: 'confirm',
          accept: () => this.saveData(),
          color: 'primary',
          title: 'Save',
          text: 'You are going to save changes'
        });
      },
      saveData() {

        let images = this.images.map(url => url.split(this.baseImagesURL).pop());

        let payload = {
          name: this.name,
          images: images
        };

        this.$vs.loading();
        this.$httpCars.patch(`gallery-folders/${this.item.id}`, payload)
          .then(r => {
            console.log(r);
            this.$vs.loading.close();
            this.$vs.notify({
              title: 'Success',
              text: 'Gallery are saved successfully!',
              color: 'success'
            });
            this.$emit('update');
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: 'Error',
              text: "Couldn't Save Data!",
              color: 'danger'
            })
          })
      },
      saveImage(imagesArray) {
        this.images = imagesArray;
        console.log(this.images);
        this.imagesCounter++;
      },
      successUpload(e) {
        console.log(e);
        let imageURL = this.baseImagesURL + JSON.parse(e.target.response).data;
        this.images.push(imageURL);
      },
      failUpload() {
        this.$vs.notify({
          title: 'Error',
          text: 'Failed to Upload Image!',
          color: 'danger'
        });
      },
      confirm_delete_image(index) {
        this.$vs.dialog({
          type: 'confirm',
          accept: () => this.delete_image(index),
          color: 'danger',
          title: 'Delete Image',
          text: 'You are going to delete this image'
        });
      },
      delete_image(index) {
        this.images.splice(index, 1);
      },
    },
    computed: {
      invalidForm() {
        if (this.flags.images || this.flags.name) {
          return true;
        } else {
          return false;
        }
      }
    },
    watch: {
      name(v) {
        v === '' ? this.flags.name = true : this.flags.name = false;
      },
      images(v) {
        v.length === 0 ? this.flags.images = true : this.flags.images = false;
      }
    }
  }
  ;
</script>

<style scoped lang="scss">
  @import "../../basic-styles/mixins";

  %flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .img-fluid {
    border: 1px solid #00000030;
    height: auto;
    width: 200px;
    max-width: 100%;
    border-radius: 20px;
    @include margin-x(20px);
  }

  .images {
    @include margin-y(30px);
    text-align: center;
  }

  .optionBtns {
    @include margin-x(20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .save {
      position: relative;
      left: 25px;
    }
  }

  .input {
    width: 100%;
    @include margin-y(30px)
  }

  .arabic, .english {
    @extend %flex;
    flex-flow: column;
    width: 100%;
    padding: 20px;
  }

  h1 {
    margin-bottom: 25px;
  }

  .inputs {
    @extend %flex;
  }

  ul {
    li {
      display: inline;
    }
  }

  .clr {
    clear: both;
  }

  $primaryColor: #054f7c;

  .breadcrumb {
    margin: 20px;
  }

  .back-to {
    cursor: pointer;
    color: $primaryColor;
    font-weight: bold;
  }

  .main-component {
    color: $primaryColor;
  }

  .required {
    color: red;
    font-size: 1.5rem;
  }

  .mandatory {
    color: red;
  }

  .arabic, .english {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
  }

  .body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  .img-uploader {
    height: 200px;
    max-width: 100%;
    width: auto;
    border-radius: 20px;
    padding: 10px
  }

  .custom-class {
    color: $primaryColor;
  }

  .title {
    font-size: 2rem;
    font-weight: 400;
  }

  .separator {
    font-size: 2rem;
    font-weight: 100;
    color: #ccc;
  }

  .label {
    color: #000;
  }

  .flex {
    display: flex;
    justify-content: flex-end;
  }

</style>
