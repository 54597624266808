<template>
    <vs-td>
        <vs-chip
            :color="getOrderStatusColor(data)"
            class="product-order-status"
        >
            {{ getOrderStatusState(data) }}
        </vs-chip>
    </vs-td>
</template>

<script>
export default {
    name: "request-status-row",
    props: ["data", "agentStatus"],
    methods: {
        getOrderStatusColor(status) {
            if (status == -1) return "danger";
            if (status == 5) return "danger";
            if (status == 0 && this.agentStatus !== "in_progress")
                return "warning";
            if (status == 0 && this.agentStatus === "in_progress")
                return "primary";
            if (status == 1) return "success";
            if (status == 2) return "dark";
            if (status == 3) return "primary";
            return "Unknown";
        },
        getOrderStatusState(status) {
            if (status == -1) return "Cancelled";
            if (status == 5) return "Not Reachable";
            if (status == 0 && this.agentStatus !== "in_progress")
                return "Pending";
            if (status == 0 && this.agentStatus === "in_progress")
                return "In Progress";
            if (status == 1) return "Accepted";
            if (status == 2) return "Scheduled";
            if (status == 3) return "Completed";
            return "Unknown";
        },
    },
};
</script>

<style scoped>
.product-order-status{
  text-align: center;
}
</style>
